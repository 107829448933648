<template>
  <div class="myInfo-container">
    <div class="content">
      <van-cell-group :border="false">
        <van-uploader
          :max-size="1024 * 1024 * 5"
          @oversize="onOversize"
          :after-read="afterRead"
        >
          <van-cell title="头像" is-link class="head-cell">
            <template #default>
              <van-icon
                :name="
                  userInfo.headImg || require('@/assets/imgs/default_img.png')
                "
                class="head-icon"
              />
            </template>
          </van-cell>
        </van-uploader>
        <van-cell title="姓名" :value="userInfo.name" />
        <van-cell title="手机号" :value="telephone" />
        <van-cell v-if="!isZdd" title="邮箱" :value="userInfo.email" />
        <van-cell title="证件号 " :value="userInfo.idCardNo" />
        <van-cell title="注册时间" :value="userInfo.createTime" />
      </van-cell-group>
    </div>
  </div>
</template>

<script>
  import Apis from '@/api/my'
  import { mapState } from 'vuex'
  import { Toast } from 'vant'

  export default {
    data() {
      return {}
    },
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
      }),
      telephone() {
        if (this.userInfo.telephone) {
          return this.userInfo.telephone.replace(
            /(\d{3})(\d{4})(\d{4})/,
            '$1****$3'
          )
        }
        return ``
      },
      isZdd() {
        return this.$store.getters['system/isZdd']
      },
    },
    methods: {
      onOversize(file) {
        Toast('图片大小不能超过 5M')
      },
      afterRead(file) {
        const imgFormat = file.file.name.split('.')
        const imgType = `.${imgFormat[imgFormat.length - 1]}`
        if (imgType !== '.jpg' && imgType !== '.jpeg' && imgType !== '.png') {
          Toast('营业执照上传，仅支持jpg、png格式')
          return
        }
        // 此时可以自行将文件上传至服务器
        Apis.updateUserHeadImg({
          objectBase64: file.content,
          objectFormat: imgType,
        }).then(res => {
          this.$store.dispatch('fetchUserInfo')
        })
      },
    },
  }
</script>

<style lang="less" scoped>
  .myInfo-container {
    width: 100%;
    height: 100%;
    .content {
      padding: 15px 0;
      .van-uploader {
        width: 100%;
      }
      /deep/.van-uploader__input-wrapper {
        width: 100%;
      }
      .head-cell {
        display: flex;
        align-items: center;
        .van-cell__title {
          line-height: 40px;
        }
        .head-icon {
          width: 40px;
          height: 40px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 22px;
            object-fit: cover;
          }
        }
      }
    }
    .van-cell {
      padding: 12px 16px;
    }
    .van-cell__value {
      color: #111a34;
    }
    .van-icon-arrow::before {
      color: #111a34;
    }
    .van-cell__title {
      color: #677283;
    }
  }
</style>
